import React, { Component } from 'react';
import './start.scss';

import acvila from '../../../assets/images/acvila.png';

class Start extends Component {

    render() {
        return (
            <div className="create-join-room-container">
                <div className="room-selection-container d-flex flex-column justify-content-center h-100">
                    <div className="logo-container d-flex flex-column align-items-center">
                        <img src={acvila} alt="acvila" className="acvila-logo" width="192" />
                        <div className="under-logo"></div>
                    </div>
                    <div className="room-title">Start to estimate in your team</div>
                    <div className="buttons-container d-flex flex-column align-items-center">
                        <button className="btn join-button" onClick={()=>this.props.history.push('/join-room')}>JOIN ROOM</button>
                        <button className="btn-light create-button" onClick={()=>this.props.history.push('/create-room')}>Create room</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default Start;
