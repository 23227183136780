import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route } from "react-router-dom";

// STYLES
import "bootstrap/dist/css/bootstrap.min.css";
import "./app/styles/main.css";

// COMPONENTS
import Welcome from "./app/components/welcome/welcome";
import SplashScreen from "./app/components/splash-screen/splash-screen";
import Start from "./app/components/start/start";
import CreateRoom from "./app/components/create-room/create-room";
import JoinRoom from "./app/components/join-room/join-room";
import Room from "./app/components/room/room";

const App = () => {
  return (
    <BrowserRouter>
      <div>
        <Route path="/" exact component={SplashScreen} /> 
        <Route path="/welcome" component={Welcome} />
        <Route path="/start" component={Start} />
        <Route path="/create-room" component={CreateRoom} />
        <Route path="/join-room" component={JoinRoom} />
        <Route path="/room/:roomId" component={Room} />
      </div>
    </BrowserRouter>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
