export function replaceURLs(message) {
    if(!message) return;

    const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return message.replace(urlRegex, function (url) {
        let hyperlink = url;
        if (!hyperlink.match('^https?:')) {
            hyperlink = 'http://' + hyperlink;
        }

        return '<a href="' + hyperlink + '" target="_blank">' + url + '</a>'
    });
}

export function checkIfEverybodyVoted(voters)  {
    return Object.keys(voters).every(voter => voters[voter].voted === true);
}
