import React, { Component } from 'react';
import './create-room.scss';
import firebase from './../../../firebase';
import acvila from '../../../assets/images/blue-logo.png';
import scrumBoard from '../../../assets/images/scrum_board_two_color.svg'
import TextField from '@material-ui/core/TextField';
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Tooltip from "@material-ui/core/Tooltip";

class CreateRoom extends Component {
  constructor(props) {
    super(props);

    this.state = {
      roomOwner: null,
      privateRoom: false,
      roomPassword: null,
      roomName: null,
      capacity: null,
      cards: null
    };
  }

  createRoom = () => {
    const isFormValid = !!document.forms[0][0].value.length && !!document.forms[0][1].value.length;
    if(isFormValid) {
      const newRoom = {
        privateRoom: this.state.privateRoom,
        roomPassword: this.state.privateRoom ? this.state.roomPassword : null,
        roomName: this.state.roomName,
        roomOwner: this.state.roomOwner,
        storyToEstimate: '',
        cards: this.state.cards?? [0.5, 1, 2, 3, 5, 8, 13, 21, 34, 40],
        capacity: this.state.capacity,
        estimationInProgress: false,
        roomReady: false,
        sessionPaused: false,
        estimatedStories: [{ storyDescription: 'dummy story', storyPoints: '0' }]
      };

      const key = firebase
          .database()
          .ref()
          .child('rooms')
          .push(newRoom).key;
      this.setState({
        roomId: key
      });
      localStorage.setItem('userSession', JSON.stringify({ roomId: key, role: 'po', active: true, myId: key }));
      this.props.history.push(`/room/${key}`);
    }
  };

  render() {
    return (
      <div className="create-room-container d-flex justify-content-center align-items-center">
        <div className="room-content">
          <div className="room-logo w-100">
            <img src={acvila} alt="acvila" className="acvila-logo" width="192" />
          </div>
          <div className="room-form-content d-flex">
            <div className="create-room-form col-md-6 col-12">
              <div className="room-illustration d-flex d-md-none flex-column align-items-center justify-content-center">
                <img src={scrumBoard} alt="scrumBoard" className="illustration-image" />
                <div className="illustration-text d-flex flex-column justify-content-center align-items-center text-center">
                  <div className="d-none d-md-block text-header pb-2">Estimate in the team</div>
                  <div className="text-description">You can estimate with your colleagues without being influenced by their estimates.</div>
                </div>
              </div>
              <form  className="form-content" name="create-room">
                <div className="form-title">Create room</div>
                <div className="form-group pb-3">
                  <TextField
                    label="Room name"
                    className="form-control"
                    id="roomName"
                    required
                    onChange={event => this.setState({ roomName: event.target.value })}
                  />
                </div>
                <div className="form-group pb-3">
                  <TextField
                    label="Your name"
                    className="form-control"
                    id="ownerName"
                    required
                    // aria-describedby="roomOwnerDescription"
                    onChange={event => this.setState({ roomOwner: event.target.value })}
                  />
                </div>
                <div className="form-group pb-3">
                  <TextField
                      label="Sprint Capacity"
                      type='number'
                      className="form-control"
                      id="capacity"
                      inputProps={{min: 0}}
                      onKeyPress={(event) => {
                        if (event?.key === '-' || event?.key === '+' || event?.key === 'e') {
                          event.preventDefault();
                        }
                      }}
                      onChange={event => this.setState({ capacity: Number(event.target.value) })}
                  />
                </div>
                <div className="form-group pb-3">
                  <RadioGroup
                      row
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="[0.5, 1, 2, 3, 5, 8, 13, 21, 34, 40, 55]"
                      name="radio-buttons-group"
                      onChange={event => this.setState({ cards: JSON.parse(event.target.value) })}
                  >
                    <Tooltip title="Fibonacci sequence: 0,5, 1, 2, 3, 5, 8, 13, 21, 34, 40... ">
                      <FormControlLabel value="[0.5, 1, 2, 3, 5, 8, 13, 21, 34, 40, 55]" control={<Radio />} label="Story-points" />
                    </Tooltip>
                    <Tooltip title="Working hours: 1, 2, 3, 4, 5, 6, 7, 8, 10, 12, 14, 16">
                      <FormControlLabel value="[1, 2, 3, 4, 5, 6, 7, 8, 10, 12, 14, 16]" control={<Radio />} label="Hours" />
                    </Tooltip>
                  </RadioGroup>
                </div>
                <button type="submit" className="btn float-right mt-5" onClick={this.createRoom}>
                  NEXT
                </button>
              </form>
            </div>
            <div className="room-illustration col-md-6 d-none d-md-flex flex-column align-items-center justify-content-center">
              <img src={scrumBoard} alt="scrumBoard" className="illustration-image" />
              <div className="illustration-text d-flex flex-column justify-content-center align-items-center text-center">
                <div className="text-header pb-2">Estimate in the team</div>
                <div className="text-description">You can estimate with your colleagues without being influenced by their estimates.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateRoom;
