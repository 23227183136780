import React, { Component } from 'react';
import './team-list.scss';

import acvila from '../../../assets/images/acvila.png';

class TeamList extends Component {
  render() {
    return (
      <div className="team-list-container">
        <div className="list-title">COORDINATOR</div>
        <div className="user-container">
          {this.props.roomOwner ? (
            <div className="owner">
              <div className="d-flex align-items-center">
                <div className="image-container">
                  <img src={acvila} alt="owner-logo" className="owner-logo" width="25" />
                </div>
                <span className="owner-name">{this.props.roomOwner}</span>
              </div>
            </div>
          ) : (<span>The coordinator has left the room</span>)}
          <div className="users-list">
            {this.props.usersList ? <div className="list-title pb-3">TEAM</div> : null}
            {this.props.usersList
              ? Object.keys(this.props.usersList).map((user, i) => (
                <div className="user d-flex align-items-center justify-content-between" key={i}>
                  <div className={`d-flex align-items-center w-100 ${!this.props.usersList[user]?.active ? 'offline' : ''}`}>
                    <div className="image-container">
                        {this.props.usersList[user]?.imageNumber ? <img
                            src={require(`../../../assets/images/users-images/${this.props.usersList[user]?.imageNumber}.png`)}
                            alt="acvila"
                            className="user-logo"
                            width="25"
                        /> : null}
                    </div>
                    <div className="user-name-container d-flex justify-content-center align-items-center w-100">
                      <span className="user-name w-100">{this.props.usersList[user].name}</span>
                      {this.props.newTopicReady && (
                        <div>
                          {this.props.storyToEstimate.voters[user]?.voted && !this.props.storyToEstimate.currentVoteDone && (
                            <span className="checkmark"></span>
                          )}
                          {!this.props.storyToEstimate.voters[user]?.voted && <span className="dot"></span>}
                          {this.props.storyToEstimate.currentVoteDone && (
                            <span className="vote-value">{this.props.storyToEstimate.voters[user]?.points}</span>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))
              : null}
          </div>
        </div>
      </div>
    );
  }
}

export default TeamList;
