import React, { Component } from 'react';
import './join-room.scss';
import acvila from '../../../assets/images/blue-logo.png';
import firebase from './../../../firebase';
import 'firebase/database';
import TextField from '@material-ui/core/TextField';
import scrumBoard from '../../../assets/images/scrum_board_two_color.svg';

class JoinRoom extends Component {
constructor(props) {
  super(props);
  this.state = {
    roomExists: true
  }
}
  joinRoom = (event) => {
    const roomId = this.props.location.state ? this.props.location.state : this.state?.roomId;
    const formHasValues = !!document.forms[0][0].value.length && !!document.forms[0][1].value.length;
    const _this = this;

    if(formHasValues) {
      event.preventDefault();
       firebase.database().ref(`/rooms/${roomId}`)
        .once("value")
        .then(function(snapshot) {
          if(!!snapshot.val()) {
            _this.setState({ roomExists: true });
            new Promise(resolve => {
              let joinRoom = firebase
                  .database()
                  .ref(`/rooms/${roomId}/connectedUsers`)
                  .push({ name: _this.state.name, role: 'dev', active: true, imageNumber: Math.floor(Math.random() * 20 + 1) }).key;
              resolve(joinRoom);
            }).then(key => {
              localStorage.setItem('userSession', JSON.stringify({ roomId: roomId, role: 'dev', myId: key }));
              _this.props.history.push(`/room/${roomId}`);
            });
          } else {
            _this.setState({ roomExists: false });
          }
        });
    }
  };

  render() {
    return (
      <div className="join-room-container d-flex justify-content-center align-items-center">
        <div className="room-content">
          <div className="room-logo w-100">
            <img src={acvila} alt="acvila" className="acvila-logo" width="192" />
          </div>
          <div className="room-form-content d-flex">
            <div className="join-room-form col-md-6 col-12">
              <div className="room-illustration d-flex d-md-none flex-column align-items-center justify-content-center">
                <img src={scrumBoard} alt="scrumBoard" className="illustration-image" />
                <div className="illustration-text d-flex flex-column justify-content-center align-items-center text-center">
                  <div className="d-none d-md-block text-header pb-2">Estimate in the team</div>
                  <div className="text-description">You can estimate with your colleagues without being influenced by their estimates.</div>
                </div>
              </div>
              <form className="form-content">
                <div className="form-title">Join room</div>
                <div className="form-group pb-3">
                  {this.props.location.state ?
                      (
                          <TextField
                              label="Room id"
                              className="form-control"
                              id="roomName"
                              required
                              value={this.props.location.state}
                              disabled={true}
                              error={!this.state?.roomExists}
                              helperText={!this.state?.roomExists ? 'Room id invalid' : ''}
                          />
                      ):
                      (
                          <TextField
                              label="Room id"
                              className="form-control"
                              id="roomName"
                              required
                              error={!this.state?.roomExists}
                              helperText={!this.state?.roomExists ? 'Room id invalid' : ''}
                              onChange={event => this.setState({ roomId: event.target.value })}
                          />
                      )
                  }

                </div>
                <div className="form-group pb-3">
                  <TextField
                    type="text"
                    label="Your name"
                    className="form-control"
                    id="ownerName"
                    required
                    onChange={event => this.setState({ name: event.target.value })}
                  />
                </div>
                <button type="submit" className="btn float-right mt-5" onClick={event => this.joinRoom(event)}>
                  JOIN
                </button>
              </form>
            </div>
            <div className="room-illustration col-md-6 d-none d-md-flex flex-column align-items-center justify-content-center">
              <img src={scrumBoard} alt="scrumBoard" className="illustration-image" />
              <div className="illustration-text d-flex flex-column justify-content-center align-items-center text-center">
                <div className="text-header pb-2">Estimate in the team</div>
                <div className="text-description">You can estimate with your colleagues without being influenced by their estimates.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default JoinRoom;
