import React, { Component } from 'react';
import { Doughnut } from 'react-chartjs-2';
import './doughnut.scss';

class DoughnutChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allVotersVoted: false
    };
  }

  colors = '';
  data = {};

  componentDidMount() {
    const points = Object.keys(this.props.voters).map(id => {
      return this.props.voters[id].voted ? this.props.voters[id].points : 0
    });

    this.colors = Object.keys(this.props.voters).map(() => {
      // generate random colors
      return '#' + Math.floor(Math.random() * (0xffffff + 1))
          .toString(16)
          .padStart(6, '0');
    });

    const pointsCount = {};
    points.forEach( (point) => { pointsCount[point] = (pointsCount[point] || 0) + 1; });

    this.data = {
      labels: Object.keys(pointsCount),
      datasets: [
        {
          data: Object.keys(pointsCount).map(point => pointsCount[point]),
          backgroundColor: this.colors
        }
      ]
    };
  }

  render() {
    return (
        <div>
          <Doughnut
              data={this.props.storyToEstimate && this.props.storyToEstimate.currentVoteDone ? this.data : null}
              width={200}
              height={250}
              options={{ maintainAspectRatio: false }}
          />
        </div>
    );
  }
}

export default DoughnutChart;
