import React, { Component } from 'react';
import Doughnut from '../../shared/doughnut/doughnut';
import './statistics-chart.scss';

class StatisticsChart extends Component {
  points = [];

  render() {
    if (this.props.storyToEstimate && this.props.storyToEstimate.voters) {
      Object.keys(this.props.storyToEstimate.voters).forEach(id => {
        if (this.props.storyToEstimate.currentVoteDone) {
          this.points.push(this.props.storyToEstimate.voters[id].points);
        }
      });
    }
    return (
      <div className="statistics-container d-flex justify-content-center">
        {this.props.storyToEstimate && this.props.storyToEstimate.voters ? (
          <div className="w-100">
            {!this.props.storyToEstimate.currentVoteDone ? (
              <div className="d-flex justify-content-center flex-column align-items-center">
                <img src={require(`../../../assets/images/Chart.svg`)} alt="chart" className="card-wait-img w-75" />
                <div className="in-progress-loader d-flex flex-column justify-content-center align-items-center">
                  <div className="in-progress-loader-text">VOTING IN PROGRESS...</div>
                  <div className="loader"></div>
                </div>

              </div>
            ) : (
              <div className="d-flex justify-content-center flex-column align-items-center w-100">
                <div className="chart-doughnut pt-4">
                  <Doughnut voters={this.props.storyToEstimate.voters} storyToEstimate={this.props.storyToEstimate} />
                </div>
                <div className="statistics-results">
                  <div className="results">
                    <div className="low">
                      <div className="text-result">
                        Lowest <br /> Estimation
                      </div>
                      <div className="low-result">{Math.min.apply(Math, this.points)}</div>
                    </div>
                    <div className="high">
                      <div className="text-result">
                        Highest <br /> Estimation
                      </div>
                      <div className="high-result">{Math.max.apply(Math, this.points)}</div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : null}
      </div>
    );
  }
}

export default StatisticsChart;
