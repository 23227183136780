import React, {Component} from 'react';
import './header.scss';
import {Dropdown} from 'react-bootstrap';
import {Modal, Button} from "react-bootstrap";
import {checkIfEverybodyVoted} from '../../utils/utils'
import headerLogo from '../../../assets/images/group-4.png';
import acvila from '../../../assets/images/acvila.png';
import firebase from './../../../firebase';
import 'firebase/database';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            active: true
        };
    }

    hideShowModal = () => {
        this.setState({isModalOpen: !this.state.isModalOpen});
    };

    confirmLeave = () => {
        const roomSession = JSON.parse(localStorage.getItem('userSession'));
        this.setState({ active: false })
        if (roomSession.role === 'po') {
            firebase
                .database()
                .ref(`/rooms/${roomSession.roomId}/roomOwner`).set(null)
        }
        firebase
            .database()
            .ref(`/rooms/${roomSession.roomId}/connectedUsers`)
            .child(roomSession.myId)
            .remove().then(() => {
                localStorage.removeItem('userSession');
                window.location = `${window.location.origin}/welcome`;
        });

        firebase
            .database()
            .ref(`/rooms/${roomSession.roomId}/storyToEstimate/voters/${roomSession.myId}/points`)
            .set(0);

        firebase
            .database()
            .ref(`/rooms/${roomSession.roomId}/storyToEstimate/voters/${roomSession.myId}/voted`)
            .set(true)
            .then(() => {
                firebase
                    .database()
                    .ref(`/rooms/${roomSession.roomId}/storyToEstimate/currentVoteDone`)
                    .set(checkIfEverybodyVoted(this.props.childrenStateData.storyToEstimate.voters));
            });

        firebase
            .database()
            .ref(`/rooms/${roomSession.roomId}/newTopicReady`)
            .set(false);
    }

    leaveRoom = () => {
        this.setState({isModalOpen: true});
    }

    render() {
        return (
            <div>
                {this.state.active && (
                    <div className="header d-flex justify-content-between align-items-center w-100">
                        <img className="header-logo" src={headerLogo} alt="group"/>
                        <div className="d-flex align-items-center ml-auto">
                            <Dropdown>
                                <Dropdown.Toggle className="leave-room" id="dropdown-basic">
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1" onClick={this.leaveRoom}>Leave Room</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <span className="user-name">
                              {this.props.childrenStateData && this.props.role === 'po'
                                  ? this.props.childrenStateData.roomOwner
                                  : this.props.childrenStateData && this.props.role === 'dev'
                                      ? this.props.childrenStateData?.connectedUsers[this.props.userId].name
                                      : ''}
                            </span>
                            <div className="image-container">
                                {this.props.childrenStateData && this.props.role === 'po' &&
                                    <img src={acvila} alt="acvila-img" className="user-logo" width="35"/>}
                                {this.props.childrenStateData && this.props.role === 'dev' && (
                                    <img
                                        src={require(`../../../assets/images/users-images/${this.props.childrenStateData.connectedUsers[this.props.userId].imageNumber
                                        }.png`)}
                                        alt="user-avatar"
                                        className="user-logo"
                                        width="35"
                                    />
                                )}
                            </div>
                        </div>
                        <Modal
                            show={this.state.isModalOpen}
                            onHide={() => this.hideShowModal()}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Are you sure you want to leave?</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>Leaving will reset the current voting process</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="primary" onClick={this.confirmLeave}>Leave</Button>
                                <Button variant="secondary" onClick={this.hideShowModal}>Stay</Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                )}
            </div>
        );
    }
}

export default Header;
