import React, { Component } from 'react';
import './estimation-cards-list.scss';

class EstimationsCardsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: -1,
      selectedCard: false,
      selectedValue: 0
    };
  }

  selectCard = (index, number) => {
    this.setState({ activeIndex: index, selectedCard: true, selectedValue: number });
  };

  sendValue = value => {
    this.props.estimatedValue(value);
  };

  render() {
    return (
      <div className="cards-list">
        <div className="numbers-list">
          {this.props?.cardsNumber.map((cardNumber, index) => (
            <div key={cardNumber} onClick={() => this.selectCard(index, cardNumber)}>
              <div id={cardNumber} className={`estimation-card-container d-flex flex-column ${this.state.activeIndex === index ? 'active' : ''}`}>
                <div className="card-top d-flex justify-content-start">{cardNumber}</div>
                <div className="card-body d-flex justify-content-center align-self-center">{cardNumber}</div>
                <div className="card-bottom d-flex justify-content-end">{cardNumber}</div>
              </div>
            </div>
          ))}
        </div>
        <div className="confirmation-button-container d-flex justify-content-center">
          <button
            type="button"
            onClick={() => this.sendValue(this.state.selectedValue)}
            className={`btn ${this.state.selectedCard ? 'btn-primary active-button' : 'btn-secondary inactive'}`}
          >
            SEND
          </button>
        </div>
      </div>
    );
  }
}

export default EstimationsCardsList;
