import React, { Component } from 'react';
import './room.scss';
import firebase from './../../../firebase';
import 'firebase/database';
import EstimationsCardsList from '../estimation-cards-list/estimation-cards-list';
import StatisticsChart from '../../shared/statistics-chart/statistics-chart';
import TeamList from '../../shared/team-list/team-list';
import Header from './../../shared/header/header';
import { replaceURLs, checkIfEverybodyVoted } from '../../utils/utils'
import shareImg from '../../../assets/images/web_development_.svg';
import shareIcon from '../../../assets/images/share.svg';
import tasksImg from '../../../assets/images/problem_solving_two_color.svg';
import waitImage from '../../../assets/images/files_and_folder.svg';
import copyImage from '../../../assets/images/copy-icon.png'

class Room  extends Component {
  constructor(props) {
    super(props);
    this.state = {
      estimationValue: 0,
      dataLoaded: false,
      cardsNumber: [],
      estimationResultValue: 0,
      usersList: [],
      currentVoteDone: false,
      estimatedStories: [],
      storyDescription: '',
      roomSession: JSON.parse(localStorage.getItem('userSession'))
    };
  }

  _isMounted = false;
  roomSession = JSON.parse(localStorage.getItem('userSession'));
  estimatedStories = [];
  storyPoints = 0;

  componentDidMount() {
    this._isMounted = true;

    if (!this.state.roomSession) {
      this.props.history.push({
        pathname: `/join-room`,
        state: this.props.match.params.roomId
    });
    } else {
      if(this.state.roomSession && this.state.roomSession.roomId !== this.props.match.params.roomId) {
        this.props.history.push({
          pathname: `/join-room`,
          state: this.props.match.params.roomId
        });
      } else {
        // find a better way to prevent user not to navigate forward after leaveing room from back button
        window.location.hash = '!';

        // Again because Google Chrome doesn't insert
        // the first hash into the history
        window.location.hash = 'Again-!';

        window.onhashchange = function () {
          window.location.hash = '!';
        };

        firebase
            .database()
            .ref(`/rooms/${this.props.match.params.roomId}`)
            .on('value', snapshot => {
              if (snapshot.val()) {
                this.setState({
                  data: snapshot.val()
                });
                const data = snapshot.val();

                this.setState({ usersList: data.connectedUsers });
                this.setState({ dataLoaded: true });
                this.setState({ cardsNumber: data.cards });
                this.estimatedStories = data.roomReady ? this.state.data.estimatedStories : [];
              }
            });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate() {
    if (!this.state.usersList) {
      firebase
        .database()
        .ref(`/rooms/${this.props.match.params.roomId}/newTopicReady`)
        .set(false);
    }
  }

  calculateCapacity = () => {
    return this.state.data.estimatedStories.reduce(function(acc, story) { return acc + Number(story.storyPoints); }, 0)
  }

  handleSelectedValue = number => {
    firebase
      .database()
      .ref(`/rooms/${this.props.match.params.roomId}/storyToEstimate/voters/${this.roomSession.myId}/points`)
      .set(number);
    firebase
      .database()
      .ref(`/rooms/${this.props.match.params.roomId}/storyToEstimate/voters/${this.roomSession.myId}/voted`)
      .set(true)
      .then(() => {
        firebase
          .database()
          .ref(`/rooms/${this.props.match.params.roomId}/storyToEstimate/currentVoteDone`)
          .set(checkIfEverybodyVoted(this.state.data.storyToEstimate.voters));
      });
  };

  updateStoryDescription = (event) => {
    this.setState({ storyDescription: event.target.value })
  }

  addUserStory = () => {
      let voters = {};
      this.setState({ currentVoteDone: false });
      Object.keys(this.state.data.connectedUsers).forEach(user => {
        voters[user] = { voted: false, points: 0 };
      });
      firebase
          .database()
          .ref(`/rooms/${this.props.match.params.roomId}/storyToEstimate`)
          .set({ storyDescription: this.state.storyDescription, voters: voters, storyPoints: 0 });
      firebase
          .database()
          .ref(`/rooms/${this.props.match.params.roomId}/newTopicReady`)
          .set(true);
  };

  roomReady = () => {
    firebase
      .database()
      .ref(`/rooms/${this.props.match.params.roomId}/roomReady`)
      .set(true);
  };

  nextStory = () => {
    this.estimatedStories.unshift({
      storyDescription: this.state.data.storyToEstimate.storyDescription,
      storyPoints: this.storyPoints
    });
    this.setState({ storyDescription: '' })
    this.setState({ estimatedStories: this.estimatedStories });
    firebase
      .database()
      .ref(`/rooms/${this.props.match.params.roomId}/newTopicReady`)
      .set(false);
    firebase
      .database()
      .ref(`/rooms/${this.props.match.params.roomId}/estimatedStories`)
      .set(this.estimatedStories);
  };

  copyRoomId = () => {
    navigator.clipboard.writeText(window.location.href);
  };

  copyEstimatedStories = () => {
    let clipboard = [];
    this.state.data.estimatedStories.forEach(story => {
      if(story.storyDescription !== 'dummy story') {
        const storyToCopy = `${story.storyDescription} - ${story.storyPoints} \n`;
        clipboard.push(storyToCopy) ;
      }
    })
    navigator.clipboard.writeText(clipboard.join(''))
  }

  render() {
    return (
      <div className="room-container">
        <Header
          userId={this.roomSession ? this.roomSession.myId : null}
          role={this.roomSession ? this.roomSession.role : null}
          childrenStateData={this.state.dataLoaded ? this.state.data : ''}
        />
        {this.state.dataLoaded ? (
          <div className="content-container">
            <div className="room-content d-flex">
              <div className="team-list d-none d-md-flex flex-column justify-content-between col-3">
                <TeamList
                  roomOwner={this.state.data.roomOwner}
                  usersList={this.state.usersList}
                  storyToEstimate={this.state.data.storyToEstimate}
                  newTopicReady={this.state.data.newTopicReady}
                />
                <div className="share d-flex flex-column justify-content-center align-items-center pt-5">
                  <img src={shareImg} alt="shareImg" className="share-image" width="150" />
                  <button type="button" className="btn share-button mt-2 mb-4" onClick={this.copyRoomId}>
                    <img src={shareIcon} alt="share-icon" className="share-icon pr-3" />
                    <span>INVITE</span>
                  </button>
                </div>
              </div>
              <div className="center-panel d-flex justify-content-center col-sm-12 col-md-9 col-lg-6">
                {this.roomSession && this.props.match.params.roomId === this.roomSession.roomId && this.roomSession.role === 'dev' ? (
                  <div className="user-voting-container w-100">
                    {this.state.data.storyToEstimate &&
                      this.state.data.newTopicReady &&
                      !this.state.data.storyToEstimate.voters[this.roomSession?.myId]?.points ? (
                        <div className="user-voting-panel">
                          <div className="story-to-estimate">
                            TOPIC: <span dangerouslySetInnerHTML={{__html: replaceURLs(this.state.data.storyToEstimate.storyDescription)}}/>
                          </div>
                          <EstimationsCardsList estimatedValue={this.handleSelectedValue} cardsNumber={this.state.cardsNumber} />
                        </div>
                      ) : (
                        <div className="card-wait d-flex flex-column justify-content-center align-items-center">
                          <img src={waitImage} alt="acvila-2" className="card-wait-img" height="75" />
                          <div className="card-wait-text">{!this.state.data.roomReady ? <div>WAITING FOR OTHERS</div> : <div>NO TOPIC</div>}</div>
                          <div className="loader-container d-flex justify-content-center align-items-center">
                            <div className="loader"></div>
                          </div>
                          <div className="topics">
                            {this.state.data.estimatedStories && this.state.data.estimatedStories.length > 1 && (

                                <div>
                                  <div className="copy" onClick={this.copyEstimatedStories}>
                                    <img src={copyImage} alt="copy"/>
                                  </div>
                                  {this.state.data.estimatedStories.map((story, index) => (
                                      <div className="topic-wrapper" key={index}>
                                        <div className="topic">
                                          <div className="topic-description" dangerouslySetInnerHTML={{__html: replaceURLs(story.storyDescription)}}/>
                                          <div className="topic-points">{story.storyPoints}</div>
                                        </div>
                                      </div>
                                  ))}
                                </div>


                            )}

                          </div>
                        </div>
                      )}
                  </div>
                ) : (
                    <div className="po w-100">
                      {this.state.data?.capacity ? (
                          <div className="capacity"
                               style={{color: this.calculateCapacity() >= this.state.data.capacity ? '#28a745' : '#ff8787'}}>
                            Capacity: {this.calculateCapacity()} / {this.state.data.capacity}
                          </div>
                      ) : null
                      }
                      {this.roomSession && this.state.data.roomReady && !this.state.data.newTopicReady && (
                        <div>
                          <div className="board">BOARD</div>
                          <form>
                            <div className="next-topic mb-2">
                              <div className="topic-info">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="inlineFormInput"
                                  minLength="5"
                                  placeholder="eg. https://jira.cgm.ag/browse/CDTE-28003 : Footer Redesign"
                                  onChange={event => (this.updateStoryDescription(event))}
                                />
                              </div>
                              <div className="add-story">
                                <button type="button" disabled={this.state?.storyDescription?.length < 5 || !this.state.usersList} className="btn btn-primary" onClick={this.addUserStory}>
                                  START
                              </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      )}

                      {!this.state.data.roomReady && (
                        <div className="po-start-room d-flex flex-column">
                          <div className="d-flex justify-content-between">
                            <div className="po-start-room-header">ADD TASK</div>
                            <button type="button" className="btn btn-primary mb-2" onClick={this.roomReady} disabled={!this.state.data.connectedUsers}>
                              Room is ready
                          </button>
                          </div>
                          <div className="po-start-room-image d-flex flex-column justify-content-center align-items-center">
                            <img src={tasksImg} alt="tasks-img"/>
                            <span className="text-center pt-5">
                              All estimated tasks will be displayed here. At stop voting you can decide what value remains final.
                          </span>
                          </div>
                        </div>
                      )}
                      <div className="story-in-progress">
                        {this.state.data.storyToEstimate && this.state.data.newTopicReady && (
                          <div>
                            <div className="in-progress">BOARD</div>
                            <div className="story-name" dangerouslySetInnerHTML={{__html: replaceURLs(this.state.data.storyToEstimate.storyDescription)}}/>
                            {this.state.data.storyToEstimate.currentVoteDone && (
                              <div className="final-value">
                                <form>
                                  <input
                                    type="number"
                                    className="form-control mb-2"
                                    placeholder="Final value"
                                    min="0"
                                    onChange={event => (this.storyPoints = event.target.value)}
                                    onKeyPress={(event) => {
                                      if (event?.key === '-' || event?.key === '+' || event?.key === 'e') {
                                        event.preventDefault();
                                      }
                                    }}
                                  />
                                  <button type="button" className="btn btn-primary mb-2" onClick={this.nextStory}>
                                    Set Final Value
                                </button>
                                </form>
                              </div>
                            )}
                          </div>
                        )}

                        {this.state.data.estimatedStories && this.state.data.estimatedStories.length > 1 && (
                          <div>

                            <div className="topics">
                              <div className="copy" onClick={this.copyEstimatedStories}>
                                <img src={copyImage} alt="copy"/>
                              </div>
                              {this.state.data.estimatedStories.map((story, index) => (
                                <div className="topic-wrapper" key={index}>
                                  <div className="topic">
                                    <div className="topic-description" dangerouslySetInnerHTML={{__html: replaceURLs(story.storyDescription)}}/>
                                    <div className="topic-points">{story.storyPoints}</div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
              </div>
              <div className="statistics d-none d-lg-flex flex-column col">
                <div className="statistics-header">RESULTS</div>
                {!this.state.data.storyToEstimate || !this.state.data.newTopicReady ? (
                  <div className="d-flex justify-content-center flex-column align-items-center">
                    <img src={require(`../../../assets/images/Chart.svg`)} alt="chart" className="card-wait-img w-75" />
                    <div className="horizontal-line mb-4"></div>
                    <div className="empty-box mt-3 w-100"></div>
                    <div className="empty-box mt-3 w-100"></div>
                    <div className="empty-box mt-3 w-100"></div>
                  </div>
                ) : (
                    <StatisticsChart
                      storyToEstimate={this.state.data.storyToEstimate}
                      estimatedStories={this.state.data.estimatedStories}
                      role={this.roomSession.role}
                    />
                  )}
              </div>
            </div>
          </div>
        ) : (
            <div className="loading-data d-flex flex-column justify-content-center align-items-center">
              <div className="loader"></div>
              <div className="loader-text">Loading...</div>
            </div>
          )}
      </div>
    );
  }
}

export default Room;
