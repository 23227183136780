import firebase from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyDOA6ZcO26jspOo9SXcgy4j0oGrAmZslP4",
  authDomain: "acvila-2f136.firebaseapp.com",
  databaseURL: "https://acvila-2f136.firebaseio.com",
  projectId: "acvila-2f136",
  storageBucket: "acvila-2f136.appspot.com",
  messagingSenderId: "634372550583",
  appId: "1:634372550583:web:4d0806a2a24d5556250232",
  measurementId: "G-1T3NX98KN7"
};

firebase.initializeApp(firebaseConfig)

export default firebase;
