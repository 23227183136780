import  React  from 'react';
import './splash-screen.scss';
import logo from '../../../assets/images/start-logo.png';
import { useHistory } from "react-router-dom";

const SplashScreen = () => {
    const history = useHistory();
    setTimeout(() => {
        history.push('/welcome')
    }, 3000);

    return (
        <div className="start-component d-flex justify-content-center align-items-center w-100">
            <img src={logo} alt="logo" className="start-logo" width="400" />
        </div>
    )
}

export default SplashScreen;
