import React, {Component} from 'react';
import './welcome.scss';

import Carousel from 'react-bootstrap/Carousel'

import firstSlide from '../../../assets/images/slide1.png'
import secondSlide from '../../../assets/images/slide2.png'
import thirdSlide from '../../../assets/images/slide1.png'

class Welcome extends Component {
    openCreateJoinRoom = () => {
        this.props.history.push('/start')
    }

    render() {
        return (
            <div className="welcome-container d-flex justify-content-center flex-column">
                <Carousel className="welcome-carousel">
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={firstSlide}
                            alt="First slide"
                        />
                        <Carousel.Caption>
                            <h3>First slide label</h3>
                            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={secondSlide}
                            alt="Third slide"
                        />

                        <Carousel.Caption>
                            <h3>Second slide label</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={thirdSlide}
                            alt="Third slide"
                        />

                        <Carousel.Caption>
                            <h3>Third slide label</h3>
                            <p>Praesent commodo cursus magna, vel nisl consectetur.</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
                <div className="d-flex justify-content-center align-self-center start-btn-container">
                    <button className="btn" onClick={this.openCreateJoinRoom}>START ESTIMATION</button>
                </div>
            </div>
        )
    }
}

export default Welcome;
